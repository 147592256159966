import { BRAND_COMPANY_DOMAIN } from './branding';

/**
 * Holds Stride contact information
 */
const strideContact = {
  phone: '(214) 775-9960',
  email: `support@${BRAND_COMPANY_DOMAIN}`,
};

export default strideContact;
