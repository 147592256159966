import { FunctionComponent } from 'react';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import strideContact from '../strideContact';

const CallUs: FunctionComponent = () => {
  return (
    <ListItem>
      <ListItemIcon>
        <CallOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          (
            <>
              Give us a call at
              {' '}
              <Link href={'tel:' + strideContact.phone} underline='hover'>
                {strideContact.phone}
              </Link>
            </>
          )
        }
        secondary={
          (
            <>
              8am - 7pm EST Monday-Friday
            </>
          )
        }
      />
    </ListItem>
  );
};

export default CallUs;
