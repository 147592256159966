import { FunctionComponent } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import strideContact from '../strideContact';

const EmailUs:FunctionComponent = () => {
  return (
    <ListItem>
      <ListItemIcon>
        <EmailOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          (
            <>
              Email us at
              {' '}
              <Link href={'mailto:' + strideContact.email} underline='hover'>
                {strideContact.email}
              </Link>
            </>
          )
        }
      />
    </ListItem>
  );
};

export default EmailUs;
