import { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Container, Typography } from '@mui/material';

import { LEGAL_NAME } from '../../constants/branding';
import useMobileDetect from '../../hooks/useMobileDetect';
import routes from '../../lib/routes';
import { LinkType } from '../../types/LinkType';

import FooterLinks from './FooterLinks';

/**
 * @todo
 * switch to Grid component
 */
const secondaryLinks: LinkType[] = [
  { caption: 'Privacy & Security', url: routes.external.privacy.url, qrDependent: false },
  { caption: 'Terms of Use', url: routes.external.terms.url, qrDependent: false  },
  { caption: 'Eligibility Criteria', url: routes.external.eligibility.url, qrDependent: false  },
  { caption: 'Get Help', url: routes.external.getHelp.url, qrDependent: false  },
];

const filterList = [routes.external.eligibilityCriteria.url];

const hideEligibilityforPages = [
  '/welcome/[b2bPartner]',
  '/welcome/register',
  '/welcome/choose-password',
];

const FOOTER_BREAKPOINT = 870;

const Footer: FunctionComponent = () => {
  const router = useRouter();
  const isMobile = useMobileDetect(FOOTER_BREAKPOINT);

  const [ footerLinks, setFooterLinks ] = useState<LinkType[] | null>(null);

  useEffect(() => {
    if (hideEligibilityforPages.includes(router.pathname)) {
      const links = secondaryLinks.filter((e) => !filterList.includes(e.url) );
      setFooterLinks(links);
    }
  }, [router]);

  return  (
    <Container>
      <Box
        data-cy='copyright'
        display='flex'
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        justifyContent='space-between'
        py={1.25}
        textAlign={isMobile ? 'center' : 'left'}
      >
        <Typography color='white'>
          &copy; {LEGAL_NAME} {new Date().getFullYear()}
        </Typography>
        {
          footerLinks && (
            <FooterLinks
              isMobile={isMobile}
              items={footerLinks}
            />
          )
        }
      </Box>
    </Container>
  );
};

export default Footer;
